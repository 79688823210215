/**
 * Revit polyfills
 *
 * The following polyfills are required for Revit 2022 and older
 */

/**
 * globalThis polyfill must be imported first before all other polyfills
 */
import 'globalthis/auto';

// Array.flat polyfill
(Array.prototype as any).flat =
  (Array.prototype as any).flat ||
  function (depth: number) {
    const flattend: any[] = [];
    (function flat(array: any[], depth: number) {
      for (let el of array) {
        if (Array.isArray(el) && depth > 0) {
          flat(el, depth - 1);
        } else {
          flattend.push(el);
        }
      }
    })(this, Math.floor(depth) || 1);
    return flattend;
  };

// Object.fromEntries polyfill
(Object as any).fromEntries =
  (Object as any).fromEntries ||
  ((entries) => {
    const result = {};
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  });

// String.replaceAll polyfill
(String.prototype as any).replaceAll =
  (String.prototype as any).replaceAll ||
  function (search, replace) {
    return this.split(search).join(replace);
  };

// queueMicrotask polyfill
if (!window.queueMicrotask) {
  window.queueMicrotask = (callback: any) => Promise.resolve().then(callback);
}
